;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"prod-v1.66.2"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT;

// sentry release name >= 40 이면 소스맵 업로드 에러남
// vX.X 또는 vX.X.X인 버전은 slice하지 않고 그대로 사용
const versionRegex = /^v\d+\.\d+(\.\d+)?$/;
const RELEASE = process.env.NEXT_PUBLIC_RELEASE
  ? process.env.NEXT_PUBLIC_RELEASE.split('-')
      .map((el) => (versionRegex.test(el) ? el : el.slice(0, 7)))
      .join('-')
  : '';
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_LOGGING_EVENT_LEVEL = (process.env
  .NEXT_PUBLIC_SENTRY_LOGGING_EVENT_LEVEL || 'warning') as Sentry.SeverityLevel;
const SENTRY_SAMPLE_RATE = Number(process.env.SENTRY_SAMPLE_RATE) || 1;
const SENTRY_TRACES_SAMPLE_RATE =
  ENVIRONMENT === 'Prod'
    ? Number(process.env.HPCNT_SENTRY_TRACES_SAMPLE_RATE) || 0.02
    : Number(process.env.HPCNT_SENTRY_DEV_TRACES_SAMPLE_RATE) || 0.05;
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || ENVIRONMENT;
const IS_SENTRY_ENABLED =
  !!process.env.NEXT_PUBLIC_SENTRY_URL && !!process.env.NEXT_PUBLIC_SENTRY_DSN;

const ignoreErrors = [
  // Not a bug
  'Abort route change', // 페이지 이탈방지위해 일부러 throw하는 에러
  'The user denied permission to use Service Worker.', // 크롬에서 service worker 미허용
  // known issue - netowrk
  /network\s?error/i,
  'Request aborted', // axios
  'Load failed', // ios) netowrk error
  // known issue - permission
  'CacheStorage', // chrome) cache storage permission
  /^Rejected$/, // chrome) service worker permission
  'SecurityError', // ios) service worker permission
  'The operation is insecure.', // firefox) cache & service worker permission bug.
  // known issue - 메모리부족
  'Unable to obtain required WebGL resource: Failed to create texture', // effect - 디바이스 메모리부족
  'The ImageBitmap could not be allocated.', // 렌더링 - 메모리 부족
  // known issue - effect
  /^jb$/, // ios chrome) wasm 파일 로딩 실패
  // known issue - 재배포 이슈
  "Unexpected token '<'",
  /Loading chunk [\d]{1,4} failed/, // antman load failed
  'https://azarlive.com/sw-prod-v', // 배포로 인해 기존버젼 service worker 파일이 s3상에서 사라진 경우
  'ChunkLoadError',
  /undefined.*autoReset/, // onetrust
  // known issue - etc
  'logMutedMessage', // edge 버그
  'requestCode', // social login
  'مُلغى', // 결제취소 아랍어
  /undefined.*_malloc/, // tfjs 파일 경로 못찾는 버그
  'The index is not in the allowed range.', // safari 구버젼
  // known issue - vendor
  /undefined.*DomainData/, // onetrust) vendor 내부 이슈
  /undefined.*Domain/, // onetrust) vendor 내부 이슈
  /undefined.*bannerGroup/, // onetrust) vendor 내부 이슈
  "null is not an object (evaluating 'this.selector.appendChild')", // onetrust) vendor 내부 이슈
  "Cannot read properties of null (reading 'document')", // chrome) cloudflare 이슈
  'webkit-masked-url', // safari) 확장프로그램 내 이슈
  'this._perf.domInteractive', // tiktok) vendor 내부 이슈
  "(evaluating 'a.N')", // gtm) vendor 내부 이슈 추정
  'Event `CustomEvent` (type=unhandledrejection) captured as promise rejection', // gtag 내 에러
  // azar server
  'Failed to fetch',
  'Request failed with',
  'timeout',
  // matata
  'Not Permission',
  'The user has already left the live',
  'This channel is already subscribed',
  'SDKServerError',
  'UnhandledRejection',
  "Failed to execute 'querySelectorAll' on 'Document': 'div:has(> iframe[id='198230182308109283091823098102938908128390'])' is not a valid selector.",
];

if (IS_SENTRY_ENABLED) {
  let errorCount = 0;
  const MAX_ERROR_COUNT = 100;

  setInterval(() => {
    errorCount = 0;
  }, 60 * 1000);

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    release: RELEASE,
    sampleRate: SENTRY_SAMPLE_RATE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.extraErrorDataIntegration({
        // limit of how deep the object serializer should go. Anything deeper than limit will
        // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
        // a primitive value. Defaults to 3.
        depth: 10,
      }),
    ],
    beforeBreadcrumb: (breadcrumb, hint) => {
      // make xhr category verbose
      if (breadcrumb.category === 'xhr' && hint?.xhr) {
        // filter responses
        return {
          type: breadcrumb.type,
          level: breadcrumb.level,
          category: breadcrumb.category,
          data: {
            ...breadcrumb.data,
            response: hint.xhr.response,
            method: hint.xhr.__sentry_xhr__.method,
            url: hint.xhr.__sentry_xhr__.url,
            statusCode: hint.xhr.__sentry_xhr__.status_code,
            statusText: hint.xhr.statusText,
            body: hint.xhr.__sentry_xhr__.body,
          },
        } as Sentry.Breadcrumb;
      }
      return breadcrumb;
    },
    beforeSend(event, hint) {
      const err = hint.originalException;
      const check = (key: 'name' | 'message') => {
        const value = err instanceof Error ? err[key] : err;
        if (
          typeof value === 'string' &&
          ignoreErrors.some((error) => {
            if (error instanceof RegExp) {
              // 정규식
              return value.match(error);
            } else {
              // 문자열
              return value.indexOf(error) >= 0;
            }
          })
        ) {
          return true;
        }
      };
      if (check('name') || check('message')) {
        return null;
      }
      if (errorCount >= MAX_ERROR_COUNT) {
        return null;
      }
      errorCount += 1;
      return event;
    },
    ignoreErrors,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    normalizeDepth: 10,
    maxBreadcrumbs: 200,
    maxValueLength: 300,
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /extension\//i,
      /chrome-extension\//i,
      /^chrome:\/\//i,
    ],
    debug: ENVIRONMENT !== 'Prod',
  });
  Sentry.getCurrentScope().setLevel(SENTRY_LOGGING_EVENT_LEVEL);
}
